<template>
  <div v-loading="loading">
    <div class="side-highlight-container">
      <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules" class="relative">
        <template v-if="showControl.isDefault">
          <div class="flex items-center justify-between w-[560px] mb-[20px]">
            <p class="font-medium text-gray-100">{{ displayText.defaultText }} - {{ defaultTitle }}</p>
            <p class="text-primary-100 underline cursor-pointer" @click="initSetting(formData.defaultKey)">{{ displayText.initSetting }}</p>
          </div>
          <ReservationNotificationContentTemplate v-if="showControl.appointmentInfo" :data.sync="formData.appointmentInfoConfig" />
          <ShopInfoNotificationContentTemplate v-if="showControl.shopInfo" :data="get(formData, 'shopInfoConfig')" :shopId="get(formData,'ShopId')" :isDefault="get(formData, 'isDefault')" />
          <CustomImageTextNotificationContentTemplate v-if="showControl.customImageText" :contentData="formData.imageTextConfig.blocks" />
          <div v-if="blockEmpty" class="text-danger">{{ displayText.emptyText }}</div>
        </template>
        <template v-else>
          <BaseElFormItem :label="displayText.templateType.title" prop="templateType">
            <div>
              <BaseElSelect v-model="formData.templateType" disabled @change="onTemplateTypeChange">
                <BaseElSelectOption
                  v-for="option in addContentTemplateTypeOptions"
                  :key="option.value"
                  :value="option.value"
                  :label="option.label"
                />
              </BaseElSelect>
              <div v-show="formData.templateType === addContentTemplateTypeOptions.customImageText.value" class="mt-[8px] text-sm text-gray-80 leading-[17.38px]">
                <!-- <p>範本用於不同的發送渠道，送出的通知內容格式不同：</p>
                <p>1. Line：圖片與文字分成各別訊息同時送出。文字樣式不能顯示 (例如：粗體、斜體)</p>
                <p>2. email：圖片與文字合併為一則 email 送出</p>
                <p>3. 簡訊：只保留文字，合併為一則訊息同時送出。文字樣式不能顯示 (例如：粗體、斜體)</p> -->
                <p>{{ displayText.templateType.customImageText }}</p>
              </div>
            </div>
          </BaseElFormItem>

          <!-- <BaseElFormItem v-show="formData.templateType === addContentTemplateTypeOptions.systemTemplate.value" label="選擇當做基底的系統預設範本" prop="type">
            <div>
              <BaseElSelect v-model="formData.type">
                <BaseElSelectOption
                  v-for="option in systemContentTemplateOptions"
                  :key="option.value"
                  :value="option.value"
                  :label="option.label"
                />
              </BaseElSelect>
              <p>以上方選擇的系統預設範本為基底，微調其中顯示的欄位或自定義文字，另存為新範本</p>
            </div>
          </BaseElFormItem> -->

          <BaseElFormItem :label="displayText.templateName.title" prop="name">
            <BaseElInput v-model="formData.name" :placeholder="displayText.templateName.placeholder" />
          </BaseElFormItem>
          <CustomImageTextNotificationContentTemplate
            v-show="formData.templateType === addContentTemplateTypeOptions.customImageText.value"
            :contentData.sync="formData.imageTextConfig.blocks"
            :class="{'empty': blockEmpty}"
            @change="onBlockChange"
          />

          <div v-if="blockEmpty" class="text-danger">{{ displayText.emptyText }}</div>
        </template>
      </BaseElForm>
    </div>

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="onCancel"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, nextTick, ref, watch } from 'vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import ReservationNotificationContentTemplate from './components/ReservationNotificationContentTemplate.vue'
import ShopInfoNotificationContentTemplate from './components/ShopInfoNotificationContentTemplate.vue'
import CustomImageTextNotificationContentTemplate from './components/CustomImageTextNotificationContentTemplate.vue'
import { get, find } from 'lodash'
import { useRoute, useRouter } from 'vue-router/composables'
import { mappingSyncData, simpleSyncFormData, useBaseForm, onFormRulesChangeClearValidate } from '@/use/useForm'
import { noEmptyRules, maxRules } from '@/validation'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { addContentTemplateTypeOptions, systemContentTemplateOptions } from '@/config/progressNotification'
import {
  CreateNotifyContentTemplate,
  UpdateNotifyContentTemplate,
  FindNotifyContentTemplate,
  FindDefaultNotifyContentTemplate,
} from '@/api/progressNotification'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'NotificationContentTemplateEdit',
  components: {
    PageFixedFooter,
    ReservationNotificationContentTemplate,
    ShopInfoNotificationContentTemplate,
    CustomImageTextNotificationContentTemplate,
  },
  // emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()
    const { initFormData, formData, formRef, loading, formSubmit } = useBaseForm()

    const targetId = computed(() => get(route.params, 'id'))
    const blockEmpty = ref(false)
    initFormData({
      name: null,
      targetType: null,
      templateType: 'customImageText',
      type: null,
      appointmentInfoConfig: null,
      shopInfoConfig: {
        actions: [],
      },
      imageTextConfig: {
        blocks: [],
      },
    })

    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        targetType: [noEmptyRules()],
        imageTextConfig: {
          blocks: [noEmptyRules()],
        },
        shopInfoConfig: {
          title: [noEmptyRules()],
          customShopAddress: [noEmptyRules()],
          actions: [],
        },
      }
      if(formData.shopInfoConfig) {
        formData.shopInfoConfig.actions.forEach((action, index) => {
          if (['googleMap', 'openLink', 'shopPhone'].includes(action.type)) {
            const baseRules = [maxRules(35, '已超過字數上限')];
            if (action.enabled) {
              rules.shopInfoConfig.actions[index] = {
                label: [noEmptyRules(), ...baseRules],
              };
              if (action.type === 'openLink') {
                rules.shopInfoConfig.actions[index].linkUrl = [noEmptyRules()];
              }
            } else {
              rules.shopInfoConfig.actions[index] = {
                label: baseRules,
              };
            }
          }
        });
      }
      return rules
    })

    // 需要時再使用：
    onFormRulesChangeClearValidate(formRef, formRules)
    const displayText = computed(() => {
      const data = {
        defaultText: '系統預設',
        initSetting: '還原初始設定',
        emptyText: '請至少新增一則文字或圖片',
        templateType: {
          title: '新增範本類型',
          customImageText: 'Line 通知不能顯示文字樣式 (例如：粗體、斜體)。圖片與文字分成各別訊息同時送出',
        },
        templateName: {
          title: '新增範本名稱',
          placeholder: '請輸入範本名稱',
        }
      }
      return data
    })
    // ?? 參照資料
    const showControl = computed(() => {
      const control = {
        isDefault: formData.isDefault,
        appointmentInfo: formData.type === systemContentTemplateOptions.appointmentInfo.value,
        shopInfo: formData.type === systemContentTemplateOptions.shopInfo.value,
        customImageText: formData.type === systemContentTemplateOptions.imageText.value,
      }
      return control
    })
    const submitData = computed(() => {
      const type = formData.templateType === addContentTemplateTypeOptions.customImageText.value ? 'imageText' : formData.type
      const { id, name, imageTextConfig, appointmentInfoConfig, shopInfoConfig, isDefault } = formData
      if (imageTextConfig && imageTextConfig.blocks && imageTextConfig.blocks.length > 0) {
        imageTextConfig.blocks.forEach(item => {
          if (item.Image) {
            item.imageId = item.Image.id
            item.Image = undefined
          }
        })
      }
      if (shopInfoConfig && shopInfoConfig.actions && shopInfoConfig.actions.length > 0) {
        shopInfoConfig.actions.forEach((action, index) => {
          if (action.type === 'openLink' && action.linkUrl === '') {
            action.linkUrl = undefined;
          }
        });
      }
      const data = {
        id,
        shopId: shopId.value,
        type,
        name,
        imageTextConfig,
        appointmentInfoConfig,
        shopInfoConfig: isDefault ? shopInfoConfig : null,
      }
      // custom handle formData transforms to a submit ready data
      return data
    })

    // 同步表單資料
    const syncFormData = (data) => {
      // 如果有原始資料 key 與 formData 的 key 命名不同時：
      // mappingSyncData({ userKey: 'idKey' }, data, formData)

      // 如果都一樣，可以簡單使用：
      simpleSyncFormData(data, formData)
    }
    const defaultTitle = computed(() => {
      // const { type } = formData
      // if (type === 'appointmentInfo') return '預約資訊範本'
      // if (type === 'shopInfo') return '店家資訊範本'
      // if (type === 'imageText') return formData.name
      // return ''
      return formData.name
    })
    const onCancel = () => {
      router.push({
        name: 'ProgressNotificationContentTemplateList',
      })
    }
    const onBlockChange = (data) => {
      formData.imageTextConfig.blocks = data
    }
    // 送出表單
    const onSubmit = async () => {
      formUtils.checkForm(formRef.value)
      const handler = async (data, { emit, loading }) => {
        const CreateDataApi = CreateNotifyContentTemplate
        const UpdateDataApi = UpdateNotifyContentTemplate
        let apiMethod
        if (!targetId.value) apiMethod = CreateDataApi
        if (targetId.value) apiMethod = UpdateDataApi

        const imageTextBlocks = get(data, 'imageTextConfig.blocks') || []
        if (formData.type !== 'imageText' || (formData.type === 'imageText' && imageTextBlocks.length)) {
          blockEmpty.value = false
          const [, err] = await apiMethod(data)
          loading.value = false
          if (err) {
            window.$message.error(err)
            return
          }
          // emit('refresh')
          router.push({
            name: 'ProgressNotificationContentTemplateList',
          })
        } else {
          blockEmpty.value = true
        }
      }
      await formSubmit(handler, submitData.value, { emit, loading })
    }

    const onTemplateTypeChange = () => {
      formData.type = null
    }
    const emptyFormData = () => {
      formData.name = null
      formData.type = null
      formData.appointmentInfoConfig = null
      formData.shopInfoConfig = null
      formData.imageTextConfig = {
        blocks: [],
      }
    }
    const initSetting = async (key) => {
      const [res, err] = await FindDefaultNotifyContentTemplate({ shopId: shopId.value, defaultKey: key })
      if (err) window.$message.error(err)
      emptyFormData()
      nextTick(() => {
        syncFormData(res)
      })
    }
    // watch imageTextConfig.blocks.length > 0 blockEmpty = false

    watch(() => get(formData, 'imageTextConfig.blocks.length'), (val) => {
      if (formData.type === 'imageText') {
        blockEmpty.value = (val === 0)
      }
    })

    onMounted(async () => {
      if (targetId.value) {
        simpleFetch(FindNotifyContentTemplate, { shopId: shopId.value, id: targetId.value },
          (res) => syncFormData(res),
        )
      }
    })

    return {
      targetId,
      loading,
      formRef,
      formData,
      formRules,
      onCancel,
      onSubmit,
      addContentTemplateTypeOptions,
      systemContentTemplateOptions,
      onTemplateTypeChange,
      defaultTitle,
      get,
      initSetting,
      blockEmpty,
      onBlockChange,
      displayText,
      showControl,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .empty {
  border: 1px solid #F56C6C;
}
</style>
